import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 页面异常
const NotFoundComponent = () =>
  import(/* webpackChunkName: "" */ "components/errorPage/404.vue");

const Home = () => import(/* webpackChunkName: "home" */ "views/home");
const HomePage = () =>
  import(/* webpackChunkName: "home" */ "views/home/home-page");
// 资源列表
const ResourceList = () =>
  import(/* webpackChunkName: "home" */ "views/resource-list");
// 播放页面
const ResourcePlay = () => import(/* webpackChunkName: "home" */ "views/play");
// 个人中心
const PersonalCenter = () =>
  import(/* webpackChunkName: "personalCenter" */ "views/personal-center");
const BasicInfo = () =>
  import(
    /* webpackChunkName: "personalCenter" */ "views/personal-center/basic-info"
  );
const PaintingRecord = () =>
  import(
    /* webpackChunkName: "personalCenter" */ "views/personal-center/painting-record"
  );
const ChangePwd = () =>
  import(
    /* webpackChunkName: "personalCenter" */ "views/personal-center/change-pwd"
  );
// const HistoryRecord = () =>
//   import(
//     /* webpackChunkName: "personalCenter" */ "views/personal-center/history-record"
//   );
const Collection = () =>
  import(
    /* webpackChunkName: "personalCenter" */ "views/personal-center/collection"
  );

const routes = [
  {
    path: "*",
    component: NotFoundComponent,
    meta: {
      title: "Not Found"
    }
  },
  {
    path: "/",
    redirect: "/home-page"
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/home-page",
        name: "HomePage",
        component: HomePage,
        meta: {
          title: "首页",
          module: "HOME"
        }
      },
      {
        path: "/:moduleType(\\d+)",
        name: "ResourceList",
        component: ResourceList,
        meta: {
          title: "",
          module: "HOME"
        }
      },
      {
        path: "/:moduleType(\\d+)/play",
        name: "ResourcePlay",
        component: ResourcePlay,
        meta: {
          title: "",
          module: "HOME"
        }
      },
      {
        path: "/personal-center",
        name: "PersonalCenter",
        component: PersonalCenter,
        meta: {
          title: "个人中心",
          module: "HOME",
          needToken: true
        },
        children: [
          {
            path: "/personal-center/basic-info",
            name: "BasicInfo",
            component: BasicInfo,
            meta: {
              title: "个人中心",
              needToken: true
            }
          },
          {
            path: "/personal-center/painting-record",
            name: "PaintingRecord",
            component: PaintingRecord,
            meta: {
              title: "绘画记录",
              needToken: true
            }
          },
          {
            path: "/personal-center/change-pwd",
            name: "ChangePwd",
            component: ChangePwd,
            meta: {
              title: "修改密码",
              needToken: true
            }
          },
          // {
          //   path: "/personal-center/history-record",
          //   name: "HistoryRecord",
          //   component: HistoryRecord,
          //   meta: {
          //     title: "最近播放",
          //     needToken: true
          //   }
          // },
          {
            path: "/personal-center/collection",
            name: "Collection",
            component: Collection,
            meta: {
              title: "我的收藏",
              needToken: true
            }
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  linkActiveClass: "router-active",
  routes
});

export default router;
