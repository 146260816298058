
import { localStorageUtil } from 'utils/util';
const state = {
    noAuthority: false, // 403
    serverError: false, // 500
    accountInfo: localStorageUtil.getItem('accountInfo') ? localStorageUtil.getItem('accountInfo') : null, // 用户信息
    isLogin: localStorageUtil.getItem('isLogin') ? true : false, // 是否登录
    syncHistory: true, // 同步历史数据
    version: null

};
export default state;