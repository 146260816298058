import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "router/config";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/index.less";
import "style/iconfont/iconfont.css";
import "./filter";
import directive from "./utils/directives.js";
import { TrackingBaiduURL } from "api/common";
import Video from "video.js";
import "video.js/dist/video-js.css";

Vue.config.productionTip = false;
Vue.prototype.$video = Video;

Vue.use(ElementUI);
Vue.use(directive);

TrackingBaiduURL();

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc();
    window.addEventListener("resize", setRemPc); //浏览器窗口大小改变时调用rem换算方法
  }
}).$mount("#app");
//rem计算
function setRemPc() {
  var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
}
