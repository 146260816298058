/**
 * 网络请求相关配置
 */

let NODE_ENV = process.env.NODE_ENV
let baseURL = ''
let trackBaseURL = ''
let FeVersion = "dd5821aa7c7743c3838017d4ee543e0b2419164c"
let versionMessage = "dd5821a - renning, Wed Aug 21 15:58:13 2024 +0800:修改绘画记录分页请求重复问题"  
let _ENV = "production"
let productType = "@PRODUCT.TYPE@"

// 生产环境
if (NODE_ENV === 'production') {
  baseURL = 'https://huahua-server.viewshare.cn'
  trackBaseURL = 'https://track-server.xiaoshanyun.com'
}

// 开发环境
if (NODE_ENV === 'development') {
  baseURL = 'https://huahua-server.viewshare.cn'
  trackBaseURL = 'https://track-server.xiaoshanyun.com'
}

const auth = {
  username: 'viewshare',
  password: '4kOiztf8EQEJt4HTCpkBPrsFO4oz0YSG'
}

export {
  baseURL,
  trackBaseURL,
  auth,
  versionMessage,
  _ENV,
  FeVersion,
  productType
}