import Vue from "vue";
import axios from "axios";
import router from "router";
import store from "store";
import Cookies from "js-cookie";
import { baseURL } from "config/config.js";
import { preventRepeatMessage, localStorageUtil } from "./util";
import { Message } from "element-ui";

// 创建instance实例
let instance = axios.create({
  baseURL,
  timeout: 1000 * 80
});

// 请求拦截
instance.interceptors.request.use(
  config => {
    if (config.headers.token !== false && Cookies.get("family_token")) {
      config.headers.Authorization = `bearer ${Cookies.get("family_token")}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 相应拦截
instance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    // if (response.data.code === 500) {
    //   store.commit("setServerError", true);
    // }
    // else if (response.data.code === 12005) { // 12005 用户所属客户的服务期限到期, 需要跳转到登录页 让用户重新登录
    //   setTimeout(() => {
    //     router.replace({
    //       path: '/home-page',
    //     });
    //   }, 500);
    //   return false;
    // }
    return response;
  },
  error => {
    if (error.message === "cancel request") {
      return;
    }

    let _path = router.history.current.fullPath;
    if (
      _path.indexOf("/home-page") >= 0 &&
      router.history.current.query.redirect
    ) {
      _path = router.history.current.query.redirect;
    }
    if (_path == "/home-page") {
      _path = "";
    }
    if (!error.response || !Cookies.get("family_token")) {
      if (error.status === 403) {
        store.commit("setNoAuthority", true);
        return;
      }
      store.commit("LOGOUT", null);
      preventRepeatMessage();
      setTimeout(() => {
        router.replace({
          path: "/home-page",
          query: {
            redirect: _path,
            reload: true
          }
        });
      }, 500);
      return false;
    }
    // 对响应错误做点什么

    switch (error.response.status) {
      // 401: 未登录  token过期
      case 401:
        store.commit("LOGOUT", null);
        preventRepeatMessage();
        setTimeout(() => {
          router.replace({
            path: "/home-page",
            query: {
              redirect: _path,
              reload: true
            }
          });
        }, 500);

        break;
      // 403 无权限
      case 403:
        store.commit("setNoAuthority", true);
        break;

        case 500:
          store.commit("setServerError", true);
          break;
  
        // 404请求不存在
      case 404:
        Message.error("请求的网络地址不存在");
        // store.commit('setNotFound', true)
        break;
      // 其他错误，直接抛出错误提示
      default:
        Message.error("网络连接错误");
    }

    // 其他处理
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @param loading 是否需要loading 默认false 是不需要laoding, true--挂载到body上, 如果需要挂载到特定的元素上 loading=dom元素
 * @returns {Promise}
 */

export function Get(url, data = {}, loading = false, others = {}) {
  let load = null;
  if (loading) {
    load = Vue.prototype.$loading;
    if (loading === true) {
      Vue.prototype.$loading.show();
    } else {
      Vue.prototype.$loading.show(loading);
    }
  }
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params: data, ...others })
      .then(response => {
        if (load) {
          load.hide();
        }
        resolve(response.data);
      })
      .catch(err => {
        if (load) {
          load.hide();
        }
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param loading 是否需要loading 默认false 是不需要laoding, true--挂载到body上, 如果需要挂载到特定的元素上 loading=dom元素
 * @returns {Promise}
 */

export function Post(url, param = {}, loading = false, others = {}) {
  let load = null;
  if (loading) {
    load = Vue.prototype.$loading;
    if (loading === true) {
      Vue.prototype.$loading.show();
    } else {
      Vue.prototype.$loading.show(loading);
    }
  }
  return new Promise((resolve, reject) => {
    instance.post(url, param, others).then(
      response => {
        if (load) {
          load.hide();
        }
        resolve(response.data);
      },
      err => {
        if (load) {
          load.hide();
        }
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @param loading 是否需要loading 默认false 是不需要laoding, true--挂载到body上, 如果需要挂载到特定的元素上 loading=dom元素
 * @returns {Promise}
 */

export function Put(url, param = {}, loading = false, others = {}) {
  let load = null;
  if (loading) {
    load = Vue.prototype.$loading;
    if (loading === true) {
      Vue.prototype.$loading.show();
    } else {
      Vue.prototype.$loading.show(loading);
    }
  }
  return new Promise((resolve, reject) => {
    instance.put(url, param, others).then(
      response => {
        if (load) {
          load.hide();
        }
        resolve(response.data);
      },
      err => {
        if (load) {
          load.hide();
        }
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @param loading 是否需要loading 默认false 是不需要laoding, true--挂载到body上, 如果需要挂载到特定的元素上 loading=dom元素
 * @returns {Promise}
 */

export function Patch(url, data = {}, loading = false, others = {}) {
  let load = null;
  if (loading) {
    load = Vue.prototype.$loading;
    if (loading === true) {
      Vue.prototype.$loading.show();
    } else {
      Vue.prototype.$loading.show(loading);
    }
  }
  return new Promise((resolve, reject) => {
    instance.patch(url, data, others).then(
      response => {
        if (load) {
          load.hide();
        }
        resolve(response.data);
      },
      err => {
        if (load) {
          load.hide();
        }
        reject(err);
      }
    );
  });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @param loading 是否需要loading 默认false 是不需要laoding, true--挂载到body上, 如果需要挂载到特定的元素上 loading=dom元素
 * @returns {Promise}
 */

export function Delete(url, data = {}, loading = false, others = {}) {
  let load = null;
  if (loading) {
    load = Vue.prototype.$loading;
    if (loading === true) {
      Vue.prototype.$loading.show();
    } else {
      Vue.prototype.$loading.show(loading);
    }
  }
  return new Promise((resolve, reject) => {
    instance.delete(url, { params: data, ...others }).then(
      response => {
        if (load) {
          load.hide();
        }
        resolve(response.data);
      },
      err => {
        if (load) {
          load.hide();
        }
        reject(err);
      }
    );
  });
}
export default instance;
