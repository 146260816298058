
import { localStorageUtil } from 'utils/util';
import Cookies from "js-cookie";
const mutations = {
  SET_DEMO: (state, data) => {
    state.demo = data;
  },

  // 403无权限
  setNoAuthority: (state, flag) => {
    state.noAuthority = flag
  },
  
  // 500
  setServerError: (state, flag) => {
    state.serverError = flag
  },
  SET_USER: (state, data) => {
    let _data = Object.assign({}, data);
    state.accountInfo = _data;
    state.isLogin = true;
    localStorageUtil.setItem('isLogin', true);
    localStorageUtil.setItem('accountInfo', _data);
  },
  SET_LOADED: (state, data) => {
    state.loaded = true;
  },
  SYNC_HISTORY: (state, data) => {
    state.syncHistory = data;
  },
  SET_VERSION: (state, data) => {
    state.version = data;
  },
  LOGOUT: (state, data) => {
    state.accountInfo = null;
    state.isLogin = false;
    localStorageUtil.removeItem('isLogin');
    localStorageUtil.removeItem('accountInfo');
    if(!data) {
      Cookies.get('family_token') && Cookies.remove('family_token');
    }
    
  },
};
export default mutations;