
import requester from 'utils/axios';
/** 
 * 获取版本数据
*/
export function requestGetVersionData() {
  const url = '/system/version';
  return requester({ url })
    .then( response => {
      return Promise.resolve(response.data)
    })
    .catch( error => {
      return Promise.reject(error)
    })
}