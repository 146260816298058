
/**
 * @Desc 多个地方共用的API
 */

import axios from 'axios';
import requester, { Get, Patch, Post, Delete } from 'utils/axios';
import { formatDateTime, localStorageUtil, LastSelectCheck, debounce } from 'utils/util';
import { originUrl } from 'config/constant';
import { baseURL, trackBaseURL, _ENV } from 'config/config.js';
import VideoStatistics from 'utils/video-statistics';

/** 
 * @desc 收藏/取消收藏
 * @param {object} param 
 */
export function reqCollectStatus(params) {
  let url = `/api/v1/personal/collect/${params.resourcePackingId}`;
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
/** 
 * 新增历史记录
**/
export function reqAddHistory(params) {
  let url = `/api/v1/student/resource/${params.resourceId}/history`;
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })

}
/** 
 * 资源统计
**/
export function SetVideoStatistics(params) {
  let userInfo = localStorageUtil.getItem('accountInfo');
  if(!userInfo) {
    userInfo = {};
  }
  if(params.resourceId && userInfo.customerId && VideoStatistics) {
    let obj = {
      type: params.type,
      resourceid: params.resourceId,
      level: params.level,
      subject: params.subject,
      userid: userInfo.userId ? userInfo.userId : 0,
      customerid: userInfo.customerId ? userInfo.customerId : 0,
      schoolid: userInfo.schoolId ? userInfo.schoolId : 0
    }
    // console.log('video statistics need send params', obj);
    let videoStatistics = new VideoStatistics({
      boxId: '#videoBox',
      trackURL: userInfo.siteId ? `${trackBaseURL}/api/v1/${userInfo.siteId}/tracking` : `${trackBaseURL}/api/v1/tracking`,
      userType: 3,
      otherTypeInit: params.type === 'preview' || params.type === 'edit' || params.type === 'download' ? obj : null,
      init: params.type ? null : obj,
    });
  }
}

/**
 * @method reqDownLoad
 * @desc 下载
 * @param  {function} progressCb：进度回调函数
 * @param  {function} cancelCb：进度回调函数
 */
export function reqDownLoad(param, progressCb, cancelCb) {
  let url = '';
  let params = {};
  let preUrl = baseURL;
  switch (param.belongType) {
    case 1: // 平台资源
      url = `/api/v1/down/platform/${param.resourceId}`;
      if (param.chapterOneId) {
        params.chapterOneId = param.chapterOneId;
      }
      if (param.chapterTwoId) {
        params.chapterTwoId = param.chapterTwoId;
      }
      if (param.chapterThreeId) {
        params.chapterThreeId = param.chapterThreeId;
      }
      params.resourceId = param.resourceId;
      break;
    case 2: // 共享资源
      url = `/api/v1/down/share/${param.resourceId}`;
      break;
    case 3: // 我的资源
      url = `/api/v1/down/storage/0`;
      params = { documentIds: param.documentIds }
      break;
    case 4: // 我的备课
      url = `/api/v1/down/prepare/${param.resourceId}`;
      break;
    case 'forms': // 教师平台使用明细下载
      url = '/api/v1/userUsedReport/downloadDetail';
      params.queryStartDate = param.queryStartDate;
      params.queryEndDate = param.queryEndDate;
      if (param.customerId) {
        params.customerId = param.customerId;
      }
      if (param.schoolId) {
        params.schoolId = param.schoolId;
      }
      break;
    case 'examination': // 试卷库
      url = `/student/resourceAuthentication/${param.resourceId}`;
      preUrl = originUrl.indexOf('localhost:') === -1 ? originUrl : 'http://student-learning.viewshare.net';
      break;
  }
  const method = 'get'
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  const responseType = 'blob';
  const CancelToken = axios.CancelToken;
  return requester.request({
    method, url, params, timeout: 10800000, baseURL: preUrl, headers, responseType,
    onDownloadProgress: function (progressEvent) {
      progressEvent.uploadId = param.belongType !== 3 ? param.resourceId : param.documentIds;
      progressEvent.fileSize = param.fileSize;
      progressCb && progressCb(progressEvent)
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelCb && cancelCb(c, param.belongType !== 3 ? param.resourceId : param.documentIds)
    })
  })
    .then(res => {
      if(param.needStatistics) {
        SetVideoStatistics({
          resourceId: param.resourceId,
          level: param.levelId,
          subject: param.subjectId,
          type: 'download'
        });
      }
      if(!res) {
        return;
      }
      const content = res.data;
      const blob = new Blob([content]);
      // 设置文件的名称
      let fileName = '';
      if (param.fileName) {
        fileName = param.fileName;
      } else {
        fileName = formatDateTime(new Date(), 'yyyyMMddHHmmss') + '.zip';
      }

      if ('download' in document.createElement('a')) { // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName;
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else { // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
      return Promise.resolve({ id: param.belongType !== 3 ? param.resourceId : param.documentIds })
    })
    .catch(err => {
      return Promise.reject(err)
    })
}



/** 
 * 获取百度统计链接字符串
**/
export function reqGetTrackBaiduURL(param) {
  let url = param && param.siteId ? `/api/v1/tracking/${param.siteId}/getSetting` : '/api/v1/tracking/getSetting';
  let params = param;
  let baseURL1 = trackBaseURL;
  const method = 'get';
  const headers = { 'Content-Type': 'application/json;charset=UTF-8' }
  const responseType = '';

  // 创建instance实例
  let instance = axios.create({
    baseURL: baseURL1,
    timeout: 1000 * 20
  });

  return instance.request({
    method, 
    url, 
    params, 
    headers, 
    responseType,
  }).then(res => {
    return Promise.resolve(res.data)
  })
  .catch(err => {
    return Promise.reject(err)
  })
}

/** 
 * 百度统计动态添加
**/
export function TrackingBaiduURL(params) {
  let trackBaiduURL = localStorageUtil.getItem('trackBaiduURL');
  let addBaidu = (data) => {
    var _hmt = _hmt || [];
    window._hmt = _hmt;
    (function() {
      var hm = document.createElement("script");
      hm.src = 'https://hm.baidu.com/hm.js?' + data;
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();
  }
  if(trackBaiduURL) {
    addBaidu(trackBaiduURL);
    let _time = new Date().getTime();
    let _time1 = localStorageUtil.getItem('trackBaiduURLUpdate');
    _time1 = _time1 ? _time1 : _time;
    if(_time - _time1 < 24 * 60 * 60 * 1000) {
      localStorageUtil.setItem('trackBaiduURLUpdate', _time1);
      return;
    }
  }
  return reqGetTrackBaiduURL(params).then(res => {
    if(res.code === 0 && res.data && res.data.jsCode) {
      if(!trackBaiduURL) {
        addBaidu(res.data.jsCode);
      }
      trackBaiduURL = res.data.jsCode;
      localStorageUtil.setItem('trackBaiduURL', trackBaiduURL);
      localStorageUtil.setItem('trackBaiduURLUpdate', new Date().getTime());
      // console.log('reqGetTrackBaiduURL', trackBaiduURL);
    }else {
      console.log('err 百度统计动态url', res);
    }
  }).catch(err => {
    console.log('err 百度统计动态url', err);
  });
}


/**
 * 百度统计事件添加
 * 
 */
export let TrackEvent = debounce(function (to) {
  let { path, query } = to;
  let userInfo = localStorageUtil.getItem('accountInfo');
  if (userInfo && userInfo.customerId && window._hmt && path) {
    let _p = 0; // 家长端：1--首页、2--列表页、3--播放页、4--个人中心
    if (path.indexOf('/home-page') !== -1) {
      _p = 1;
    // } else if (path.indexOf('/play') !== -1 ) {
    //   _p = 3;
    // } else if (path.indexOf('/12156') !== -1 || path.indexOf('/12158') !== -1 || path.indexOf('/12157') !== -1 || path.indexOf('/12155') !== -1 || path.indexOf('/12159') !== -1) {
    //   _p = 2;
    } else if (path.indexOf('/personal-center') !== -1) {
      _p = 4;
    }
    if (_p) {
      window._hmt.push(['_trackEvent', `p_${_p}_${userInfo.customerId}`, 'page', '']);
      // window._hmt.push(['_setCustomVar', 1, 'customer', `p_${_p}_${userInfo.customerId}`, 3]);
      console.log(`p_${_p}_${userInfo.customerId}`);
    }
  }
}, 500, false);

/** 
 * 未登录时存储播放记录
**/
export function LocalHistory(type, item) {
  let localHistory = localStorageUtil.getItem('localHistory');
  if(!localHistory) {
    localHistory = [];
  }
  let i = localHistory.findIndex(ele => ele.resourcePackingId === item.resourcePackingId);
  if(type === 'collection' || type === 'play-list') {
    if(i !== -1) {
      localHistory[i].playedTime = formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss');
      localHistory = [...localHistory];
    }
  }else {
    if(i !== -1) {
      localHistory.splice(i, 1);
    }
    localHistory.unshift(Object.assign({}, item, {playedTime: formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')}));
  }
  
  localStorageUtil.setItem('localHistory', localHistory);
}