import Vue from 'vue'

Vue.filter('tranformTime', function (value) {
  let hh, mm, ss
 
  //传入的时间为空或小于0
  if(value == null || value < 0){
    return '00:00:00'
  }
  //得到小时
  hh = value/3600 | 0
  value = Math.round(value) - hh*3600
  if (parseInt(hh) < 10){
    hh = "0" + hh
  }
  //得到分
  mm = value/60 | 0
  //得到秒
  ss = parseInt(value) - mm*60
  if(parseInt(mm) < 10){
    mm = "0" + mm    
  }
  if(ss < 10){
    ss = "0" + ss     
  }
  return (hh === "00" ? '' : hh+ ":") + mm + ":" + ss
})


/**
 * 文件大小
 */ 
Vue.filter('fileSize', function (value) {
  if (!value) {
    return '0KB';
  }
  var size = "";
  if (value < 0.9 * 1024) { //如果小于0.9KB转化成B
    size = value.toFixed(2) + "B";
  } else if (value < 0.9 * 1024 * 1024) {//如果小于0.9MB转化成KB
    size = (value / 1024).toFixed(2) + "KB";
  } else if (value < 0.9 * 1024 * 1024 * 1024) { //如果小于0.9GB转化成MB
    size = (value / (1024 * 1024)).toFixed(2) + "MB";
  } else { //其他转化成GB
    size = (value / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  var sizestr = size + "";
  var len = sizestr.indexOf("\.");
  var dec = sizestr.substr(len + 1, 2);
  if (dec == "00") {//当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return sizestr;
  
  // if(value==0){
  //   return "0B"
  // }else
  // if (null == value || value == '') {
  //   return ""
  // }
  // var unitArr = new Array("B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB")
  // var index = 0
  // var srcsize = parseFloat(value)
  // index = Math.floor(Math.log(srcsize) / Math.log(1024))
  // var size = srcsize / Math.pow(1024, index)
  // // 保留一个小数
  // size = size.toFixed(1)
  // // 小数为0则去除
  // size = size.toString().indexOf('.0') !== -1 ? parseInt(size) : size
  // return size +' '+ unitArr[index]
})

/**
 * 判断 文件来源  1:平台资源 2:共享资源 3:原创资源 11:教案模板 12:课件模板
 */
Vue.filter('resourceFrom', function (value) {
  switch (value) {
    // 成功
    case 1:
      return "平台资源"
    // 空间不足
    case 2:
      return "共享资源"
    case 3:
      return "原创资源"
    case 11:
      return "教案模板"
    case 12:
      return "课件模板"

    default:
      return ""
  }
})

/**
 *  资源类型 判断  0:其它 1:Music 2:Video 3:Photo 4:doc 5:ppt 6:pdf 7:xls 8:zip
 */
Vue.filter('resourceType', (value)=>{
  switch (value) {
    // 成功
    case 1:
      return "音频"
    // 空间不足
    case 2:
      return "视频"
    case 3:
      return "图片"
    case 4:
      return "doc"
    case 5:
      return "ppt"
    case 6:
      return "pdf"
    case 7:
      return "xls"
    case 8:
      return "zip"
    case 9:
      return "交互课件"
    case 10:
      return "教案课件"

    default:
      return ""
  }
})
/** 
 * 时间格式化
*/
Vue.filter('formatDateTime', (dateTime, type) => {
  let str = ""; //存储时间的字符串
  let dt = dateTime;
  if(!dt) {
    return '';
  }else if(!isNaN(dt)) {
    dt = new Date(dt);
  }
  
  //获取年
  let year = dt.getFullYear();
  //获取月
  let month = dt.getMonth() + 1;
  //获取日
  let day = dt.getDate();
  //获取小时
  let hour = dt.getHours();
  //获取分钟
  let min = dt.getMinutes();
  //获取秒
  let sec = dt.getSeconds();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;
  sec = sec < 10 ? "0" + sec : sec;
  if (!type) {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy-MM-dd HH:mm:ss') {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy/MM/dd HH:mm:ss') {
    return str = year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy-MM-dd HH:mm') {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min;
  }
  if (type == 'MM-dd HH:mm') {
    return str = month + "-" + day + " " + hour + ":" + min;
  }
  if (type == 'yyyy-MM-dd HH:mm:ss') {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy/MM/dd HH:mm') {
    return str = year + "/" + month + "/" + day + " " + hour + ":" + min;
  }
  if (type == 'yyyy/MM/dd HH:mm:ss') {
    return str = year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy-MM-dd') {
    return str = year + "-" + month + "-" + day;
  }
  if (type == 'yyyy/MM/dd') {
    return str = year + "/" + month + "/" + day;
  }
  if (type == 'yyyy年MM月dd日') {
    return str = year + "年" + month + "月" + day + '日';
  }
  if (type == 'yyyy年MM月dd日 HH时mm分ss秒') {
    return str = year + "年" + month + "月" + day + '日' + hour + "时" + min + "分" + sec + "秒";
  }
  if (type == 'yyyy年MM月dd日 HH时mm分') {
    return str = year + "年" + month + "月" + day + '日' + hour + "时" + min + "分";
  }
  if (type == 'yyyy年MM月dd日 HH:mm:ss') {
    return str = year + "年" + month + "月" + day + '日' + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy年MM月dd日 HH:mm') {
    return str = year + "年" + month + "月" + day + '日' + hour + ":" + min;
  }
  if (type == 'HH:mm:ss') {
    return str = hour + ":" + min + ":" + sec;
  }
  if (type == 'HH:mm') {
    return str = hour + ":" + min;
  }
  if (type == 'yyyyMMddHHmmss') {
    return str = year + "" + month + "" + day + "" + hour + "" + min + "" + sec;
  }
  return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
})
/**
 * 年龄格式化（2 岁 1 个月）
 */
Vue.filter('age', function(value) {
  let val = new Date(value);
  let now = new Date();
  val = val.valueOf();
  now = now.valueOf();
  var c = now - val;
  c = new Date(c);
  let yy = c.getFullYear() - 1970;
  let mm = c.getMonth();
  let dd = c.getDate() - 1;
  if(yy && mm) {
    return yy + '岁' + mm + '个月'
  }else if(yy && !mm) {
    return yy + '岁'
  }else if(!yy && mm) {
    return mm + '个月'
  }else if(!yy && !mm) {
    return 1 + '个月'
  }else {
    return '';
  }
})